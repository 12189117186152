import React, { useEffect, useState } from "react";
import lift from "../images/Amenity tile-01.webp";
import tap from "../images/Amenity_tile-02_optimized.webp";

import tile from "../images/Amenity_tile-03_optimized.webp";
import "../style/PostProximity.css";
const PostProximity = () => {
  console.log("screen width", window.screen.width);
  const [postProximityState, setPostProximity] = useState({
style:{
  translate: window.screen.width - 1440
}
  })
  useEffect(() => {

  },[ postProximityState.style.translate])
  return (
    <>
    <div className="post-proximity post-proximity-desktop">
      <div className="post-proximity-block-1">
        <div className="post-proximity-block-1-img">
          <img loading="lazy" className="post-proximity-lift" src={lift} alt="lift" />
        </div>
        <div className="post-proximity-block-1-desc">
          <p className="post-proximity-block-1-desc-title">
            Constructing with <br />
            precision, Delivering <br /> with pride.
          </p>
          <p className="post-proximity-block-1-desc-info">
            We ensure superior construction quality using only top-tier brands
            and materials.
          </p>
        </div>
      </div>
      <div className="post-proximity-block-2" >
        <div className="post-proximity-block-2-desc">
          <p><span>Steel:</span> Tata / Jindal / Equivalent TMT Steel Bars</p>
          <p><span>Cement:</span> 53 Grade OPC UltraTech / Equivalent Cement</p>
          <p><span>RMC:</span> UltraTech / Equivalent Cement</p>
        </div>
        <div className="post-proximity-block-2-img">
          <img loading="lazy" src={tap} alt="tap" className="post-proximity-tap" />
        </div>
      </div>
      <div className="post-proximity-block-3">
        <div className="post-proximity-block-3-img">
          <img  loading="lazy" src={tile} alt="tile" className="post-proximity-tile" />
        </div>
        <div className="post-proximity-block-3-desc">
          <p>
           <span>Tiles:</span>  RAK Ceramics / Kajaria Ceramics Double Charged Vitrified /
            Glazed Tiles
          </p>
          <p> <span>CP Bath Fittings:</span>  Jaquar / Grohe / Astral / Equivalent brand</p>
          <p><span>Lift:</span> Otis / ThyssenKrupp / Kone / Equivalent brand</p>
          <p><span>Paint:</span> Asian Paints Ultima Protek</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default PostProximity;
