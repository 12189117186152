import React, { useState } from "react";
import "../style/Header.css";
import GP_logo_01 from "../images/GP_logo_01.webp";
import skyLark_log from "../images/skyLark_logo.webp";

const HeaderComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollToElement = (id, event) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const offsetElement = document.querySelector(".header");
      const offset = offsetElement ? offsetElement.offsetHeight : 0;
      const extraMargin = 40;
      const elementPosition = element.offsetTop - offset - extraMargin;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="fixed-header">
        <header className="header">
          <div className="logo-left">
            <img src={GP_logo_01} alt="GP Logo" loading="lazy"/>
          </div>

          <div className="logo-right">
            <img src={skyLark_log} alt="Skylark Logo" loading="lazy"/>
          </div>

          <div className="menu-icon" onClick={toggleMenu}>
            <div className={menuOpen ? "bar1 open" : "bar1"}></div>
            <div className={menuOpen ? "bar2 open" : "bar2"}></div>
            <div className={menuOpen ? "bar3 open" : "bar3"}></div>
          </div>
        </header>
        <div className={`navLinks ${menuOpen ? "open" : ""}`}>
          <a
            onClick={(e) => scrollToElement("about-skylark", e)}
            href="#about-skylark"
          >
            About Skylark
          </a>
          <a
            onClick={(e) => scrollToElement("configurations", e)}
            href="#configurations"
          >
            Configurations
          </a>
          <a
            onClick={(e) => scrollToElement("project-location", e)}
            href="#project-location"
          >
            Project Location
          </a>
          <a
            onClick={(e) => scrollToElement("floor-plans", e)}
            href="#floor-plans"
          >
            Floor Plans
          </a>
          <a
            onClick={(e) => scrollToElement("gp-advantage", e)}
            href="#gp-advantage"
          >
            The GP Advantage
          </a>
          <a
            onClick={(e) => scrollToElement("enquiry-form", e)}
            href="#enquiry-form"
          >
            Enquiry Form
          </a>
          <a
            onClick={(e) => scrollToElement("contact-us", e)}
            href="#contact-us"
          >
            Contact Us
          </a>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
