import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../style/EnquiryForm.css";

const EnquiryFormComponent = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    switch (name) {
      case "name":
        newValue = value.slice(0, 100).replace(/[^a-zA-Z\s]/g, "");
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: newValue.length === 0 ? "Name is required" : "",
        }));
        break;
      case "email":
        newValue = value.slice(0, 100);
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(newValue)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Please enter a valid email address",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));
        }
        break;
      case "mobile":
        newValue = value.slice(0, 10).replace(/\D/g, "");
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile:
            newValue.length !== 10
              ? "Mobile number must be exactly 10 digits"
              : "",
        }));
        break;
      case "message":
        newValue = value.slice(0, 350).replace(/[^\w\s.,!?]/g, "");
        setErrors((prevErrors) => ({
          ...prevErrors,
          message: newValue.length === 0 ? "Message is required" : "",
        }));
        break;
      default:
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);
    setIsSubmitted(true);

    const requiredFields = ["name", "email", "mobile"];

    const newErrors = {};
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
        hasErrors = true;
      } else {
        newErrors[field] = "";
      }
    });

    setErrors(newErrors);

    if (hasErrors) {
      setIsSubmitting(false);
      return;
    }

    const jsonData = JSON.stringify(formData);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer sCZhEkrTkD5s");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.shvx.in/api/v1/lead/create",
        requestOptions
      );

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          mobile: "",
          message: "",
        });
        setIsSubmitted(false);
        navigate("/thank-you");

        // Log the response data
        const responseData = await response.json();
        console.log("Form submission response:", responseData);
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="enquiry-form-1">
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          label="Name"
          name="name"
          fullWidth
          value={formData.name}
          onChange={handleChange}
          className="custom-textfield"
          required
          autoComplete="name"
          error={isSubmitted || !!errors.name}
          helperText={isSubmitted || errors.name}
        />
        <TextField
          variant="outlined"
          label="Email"
          type="email"
          name="email"
          fullWidth
          value={formData.email}
          onChange={handleChange}
          className="custom-textfield"
          required
          autoComplete="email"
          error={isSubmitted || !!errors.email}
          helperText={isSubmitted || errors.email}
        />
        <TextField
          variant="outlined"
          label="Mobile"
          type="tel"
          name="mobile"
          fullWidth
          value={formData.mobile}
          onChange={handleChange}
          className="custom-textfield"
          required
          error={isSubmitted || !!errors.mobile}
          helperText={isSubmitted || errors.mobile}
        />
        <TextField
          variant="outlined"
          label="Message"
          multiline
          rows={4}
          name="message"
          fullWidth
          value={formData.message}
          onChange={handleChange}
          className="custom-textfield"
        />
        <Button variant="contained" type="submit" className="submit-button">
          {isSubmitting ? "Submitting..." : "SUBMIT"}
        </Button>
      </form>
    </div>
  );
};

export default EnquiryFormComponent;
