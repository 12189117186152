import React from "react";
import { Box, Card, CardContent, useMediaQuery, useTheme } from "@mui/material";
import "../style/Configurations.css";

const ConfigurationsComponent = () => {
  const configurations = [
    { unit: "1 BHK Residences", area: "335 sq.ft." },
    { unit: "2 BHK Residences", area: "718 - 747 sq.ft." },
    { unit: "Jodi Residences", area: "1477 sq.ft." },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <Box className="configurations-root" id="configurations">
        <h2 className="configurations-title">CONFIGURATIONS</h2>
        <Card className="config-card">
        <CardContent className="config-content">
            {configurations.map((config, index) => (
              <div key={index} className="config-item">
                <div className="config-row">
                  <p className="config-label">Unit - </p>
                  <p className="config-value">{config.unit}</p>
                </div>
                <div className="custom-line"></div>
                <div className="config-row">
                  <p className="config-label">Carpet Area -</p>
                  <p className="config-value">{config.area}</p>
                </div>
              </div>
            ))}
          </CardContent>
        </Card>
        <p className="configurations-text">
          Spacious 2 BHK and Jodi residences in the heart of Vile Parle (W).
          <br />
          <span>Starting at ₹39,999* per sq.ft.</span>
        </p>
      </Box>
    );
  }

  return (
    <Box className="configurations-root" id="configurations">
      <h2 className="configurations-title">CONFIGURATIONS</h2>
      <Box className="configurations-cards">
        {configurations.map((config, index) => (
          <Card key={index} className="config-card">
            <CardContent className="config-content">
              <p className="config-title" variant="h6" component="div">
                Unit
              </p>
              <p className="config-subtitle" variant="body1">
                {config.unit}
              </p>
              <div className="custom-line"></div>
              <p
                className="card-area-title"
                variant="h6"
                component="div"
                style={{ marginTop: "10px" }}
              >
                Carpet Area
              </p>
              <p className="card-area" variant="body1">
                {config.area}
              </p>
            </CardContent>
          </Card>
        ))}
      </Box>
      <p className="configurations-text">
        Spacious 2 BHK and Jodi residences in the heart of Vile Parle (W).
        <br />
        {/* Starting at ₹39,999* per sq.ft. */}
        <span> Starting at ₹39,999* per sq.ft.</span>
      </p>
    </Box>
  );
};

export default ConfigurationsComponent;
