import React from "react";
import { Box, Container, Link, Grid, IconButton } from "@mui/material";
import "../style/Footer.css";
import LocationOnIcon from "../images/LocationOnIcon.webp";
import PhoneIcon from "../images/PhoneIcon.webp";
import EmailIcon from "../images/EmailIcon.webp";
import LanguageIcon from "../images/LanguageIcon.webp";
import FacebookIcon from "../images/FacebookIcon.webp";
import InstagramIcon from "../images/InstagramIcon.webp";
import TwitterIcon from "../images/TwitterIcon.webp";
import LinkedInIcon from "../images/LinkedInIcon.webp";

const FooterComponent = () => {
  return (
    <>
      <Box
        id="contact-us"
        className="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ textAlign: "center" }}>
            <h2 className="footer-title">CONTACT US</h2>
          </Box>
          <Grid container spacing={2} justifyContent="center" gap="10px">
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="start"
              justifyContent="start"
            >
              <IconButton disabled sx={{ mr: 1 }} style={{ paddingTop: "0" }}>
                <img
                loading="lazy"
                  src={LocationOnIcon}
                  alt="Location"
                  className="footer-icon"
                />
              </IconButton>
              <span className="footer-icon-text">
                001, Shree Apartments, Gr. Floor, Dr. M. B. Raut road, <br />
                Shivaji Park, Dadar West, Mumbai - 400028
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="start"
              justifyContent="start"
            >
              <IconButton disabled sx={{ mr: 1 }} style={{ paddingTop: "0" }}>
                <img loading="lazy" src={PhoneIcon} alt="Phone" className="footer-icon" />
              </IconButton>

              <span className="footer-icon-text">
                <Link href="tel:022-24473000" color="inherit">
                  022-24473000
                </Link>
                <br />
                <Link href="tel:9867953968" color="inherit">
                  9867953968
                </Link>
                <br />
                <Link href="tel:9870839081" color="inherit">
                  9870839081
                </Link>
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="start"
            >
              <IconButton disabled sx={{ mr: 1 }} style={{ paddingTop: "0" }}>
                <img loading="lazy" src={EmailIcon} alt="Email" className="footer-icon" />
              </IconButton>
              <Link
                href="mailto:info@gpdevelopers.co.in"
                color="inherit"
                className="footer-icon-text"
              >
                info@gpdevelopers.co.in
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="start"
            >
              <IconButton disabled sx={{ mr: 1 }} style={{ paddingTop: "0" }}>
                <img loading="lazy" src={LanguageIcon} alt="Website" className="footer-icon" />
              </IconButton>
              <Link
                href="http://gpdevelopers.co.in"
                target="_blank"
                rel="noopener"
                underline="none"
                className="footer-icon-text"
              >
                <span> gpdevelopers.co.in</span>
              </Link>
            </Grid>
          </Grid>
          <Grid container alignItems="center" className="copyright-container" >
            <Grid item xs={12} sm={9}>
              <Box className="footer-copyright-div">
                <span className="footer-copyright-text">
                  Copyright &copy; 2024 All rights reserved Privacy Policy |
                  Terms & Conditions
                </span>
              </Box>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Box
                sx={{
                  mt: { xs: 2, sm: 0 },
                }}
                className="footer-social-box"
              >
                <Link
                  href="https://www.facebook.com/GPDevelopersMumbai"
                  target="_blank"
                  color="inherit"
                  sx={{ mx: 1 }}
                >
                  <IconButton sx={{ p: 0 }}>
                    <img
                    loading="lazy"
                      src={FacebookIcon}
                      alt="Facebook"
                      className="footer-icon"
                    />
                  </IconButton>
                </Link>
                <Link
                  href="https://www.instagram.com/gpcdevelopers/"
                  target="_blank"
                  color="inherit"
                  sx={{ mx: 1 }}
                >
                  <IconButton sx={{ p: 0 }}>
                    <img
                    loading="lazy"
                      src={InstagramIcon}
                      alt="Instagram"
                      className="footer-icon"
                    />
                  </IconButton>
                </Link>
                <Link
                  href="https://x.com/gpcdevelopers"
                  target="_blank"
                  color="inherit"
                  sx={{ mx: 1 }}
                >
                  <IconButton sx={{ p: 0 }}>
                    <img
                    loading="lazy"
                      src={TwitterIcon}
                      alt="Twitter"
                      className="footer-icon"
                    />
                  </IconButton>
                </Link>
                <Link
                  href="https://www.linkedin.com/company/g-p-developers/"
                  color="inherit"
                  target="_blank"
                  sx={{ mx: 1 }}
                >
                  <IconButton sx={{ p: 0 }}>
                    <img
                    loading="lazy"
                      src={LinkedInIcon}
                      alt="LinkedIn"
                      className="footer-icon"
                    />
                  </IconButton>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <div className="custom-line"></div>
      <Box textAlign="start" mt={2} className="footer-disclaimer">
        <p>
          The floor plan, specifications, images, and other details shown are
          for marketing purposes, and are to be used as a guide only. Floor plan
          is intended to give a general indication of the proposed layout and
          may vary in the finished building. All drawings/dimensions shown are
          for illustrative purpose only, and may not be accurate representations
          of final construction and are not intended to form part of any
          contract or warranty unless specifically incorporated in writing into
          the contract. RERA No: P51800050748
        </p>
      </Box>
    </>
  );
};

export default FooterComponent;
