import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "../images/closeIcon.webp";
import welcomeImg from "../images/Welcome_4_optimized.webp";
import "../style/HomePage.css";

const HomePageComponent = () => {
  const navigate = useNavigate();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEnquireButtonClick = () => {
    setIsFormVisible(true);
  };

  const handleCloseButtonClick = () => {
    setIsFormVisible(false);
    setIsSubmitted(false);
    setErrors({
      name: "",
      email: "",
      mobile: "",
      message: "",
    });
    setFormData({
      name: "",
      email: "",
      mobile: "",
      message: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    switch (name) {
      case "name":
        newValue = value.slice(0, 100).replace(/[^a-zA-Z\s]/g, ""); 
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: newValue.length === 0 ? "Name is required" : "",
        }));
        break;
        case "email":
          newValue = value.slice(0, 100); 
          if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(newValue)) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              email: "Please enter a valid email address",
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              email: "",
            }));
          }
        break;
      case "mobile":
        newValue = value.slice(0, 10).replace(/\D/g, "");
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile:
            newValue.length !== 10
              ? "Mobile number must be exactly 10 digits"
              : "",
        }));
        break;
        case "message":
          newValue = value.slice(0, 350).replace(/[^\w\s.,!?]/g, ''); 
          setErrors((prevErrors) => ({
            ...prevErrors,
            message: newValue.length === 0 ? "Message is required" : "",
          }));
        break;
      default:
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);
    setIsSubmitted(true);

    const requiredFields = ["name", "email", "mobile"];

    const newErrors = {};
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
        hasErrors = true;
      } else {
        newErrors[field] = "";
      }
    });

    setErrors(newErrors);

    if (hasErrors) {
      setIsSubmitting(false);
      return;
    }

    const jsonData = JSON.stringify(formData);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer sCZhEkrTkD5s");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.shvx.in/api/v1/lead/create",
        requestOptions
      );

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          mobile: "",
          message: "",
        });
        setIsSubmitted(false);
        navigate("/thank-you");

        // Log the response data
        const responseData = await response.json();
        console.log("Form submission response:", responseData);
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <div className="parent-container">
        <div className="city-view">
          <div className="image-title">
            <p>Experience the green side</p>
            <p>of city living.</p>
          </div>
        </div>
        <div className="color-background"></div>
        <img
          loading="lazy"
          src={welcomeImg}
          alt="Welcome to Skylark"
          className="welcomeImage"
        />
        <button
          className="enquireButton"
          onClick={handleEnquireButtonClick}
          disabled={isFormVisible}
        >
          ENQUIRE NOW
        </button>
        <div
          className={`enquire-form-container ${isFormVisible ? "visible" : ""}`}
        >
          <div className="enquiry-form">
            <div className="enquiry-form-optin-close">
              <IconButton onClick={handleCloseButtonClick}>
                <img
                  loading="lazy"
                  src={CloseIcon}
                  alt="CloseIcon"
                  className="close-button"
                />
              </IconButton>
            </div>
            <div className="form-content">
              <form
                className="enquiry-form-optin"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  autoComplete="name"
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={!!errors.email}
                  helperText={errors.email}
                  autoComplete="email"
                />
                <TextField
                  label="Mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                  inputProps={{
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    maxLength: 10,
                  }}
                />
                <TextField
                  label="Message"
                  name="message"
                  variant="outlined"
                  margin="normal"
                  value={formData.message}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                  // error={!!errors.message}
                  // helperText={errors.message}
                />
                <Button
                  variant="contained"
                  type="submit"
                  className="submit-btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "SUBMIT"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <p className="addressText">
        Skylark, CTS No. 811/812, Opp. Dolphin Aquarium, Irla Passage Road, Vile
        Parle (West), Mumbai - 400056. <b>(RERA No: P51800050748)</b>
      </p>
    </>
  );
};

export default HomePageComponent;
