import "./App.css";
import ConfigurationsComponent from "./components/ConfigurationsComponent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeaderComponent from "./components/HeaderComponent";
import HomePageComponent from "./components/HomePageComponent";
import ProximityComponent from "./components/ProximityComponent";
import FooterComponent from "./components/FooterComponent";
import FloorPlanComponent from "./components/FloorPlanComponent";
import ProjectCarouselComponent from "./components/ProjectCarouselComponent";
import PostConfigComponent from "./components/PostConfigComponent";
import ProjectLocation from "./components/ProjectLocation";
import PostProximity from "./components/PostProximity";
import AboutComponent from "./components/AboutComponent";
import FormContainer from "./components/FormContainer";
import BeforeFloorPlanning from "./components/BeforeFloorPlanning";
import PostProximityMobile from "./components/PostProximityMobile";
import ThankYouPage from "./Page/ThankYouPage";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <HeaderComponent />
              <HomePageComponent />
              <ConfigurationsComponent />
              <PostConfigComponent />
              <ProjectLocation />
              <ProximityComponent />
              <PostProximity />
              <PostProximityMobile />
              <div className="after-proximity-container">
                <BeforeFloorPlanning />

                <>
                  <div className="beforeFloorPlanning-block-2">
                    <p>
                      Imagine waking up to the soothing sounds of nature and
                      stepping out onto your own private sanctuary in the sky.
                      Skylark’s rooftop garden offers the perfect setting for
                      you to unwind, recharge, and pursue your dreams with
                      renewed vigour.
                    </p>
                    <p>
                      This is a spot where every member of your family can find
                      their own slice of paradise. Whether it’s a quiet corner
                      for parents to unwind after a long day, a vibrant space
                      for kids to explore and play, or a scenic backdrop for
                      family gatherings and celebrations, our rooftop garden
                      offers the perfect blend of tranquillity and excitement
                      for everyone to enjoy.
                    </p>
                  </div>
                </>
              </div>
              <FloorPlanComponent />
              <ProjectCarouselComponent />
              <AboutComponent />
              <FormContainer />
              <FooterComponent />
            </>
          }
        />
        <Route
          exact
          path="/thank-you"
          element={
            <>
              <div className="blur-background">
                <HeaderComponent />
                <HomePageComponent />
              </div>
              <ThankYouPage />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
