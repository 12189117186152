import React from 'react';
import { Box } from '@mui/material';
import '../style/Proximity.css';

// Replace with the actual paths to your icon images
import vileParleStationIcon from "../images/vileParleStationIcon.webp";
import svRoadIcon from "../images/svRoadIcon.webp";
import captGoreFlyoverIcon from "../images/captGoreFlyoverIcon.webp";
import andheriStationIcon from "../images/vileParleStationIcon.webp";
import petrolPumpIcon from "../images/petrolPumpIcon.webp";
import banksIcon from "../images/banksIcon.webp";
import irlaMarketIcon from "../images/irlaMarketIcon.webp";
import parleMarketIcon from "../images/irlaMarketIcon.webp";
import nmimsIcon from "../images/nmimsIcon.webp";
import cnmSchoolIcon from "../images/cnmSchoolIcon.webp";
import klsHospitalIcon from "../images/klsHospitalIcon.webp";
import nanavatiHospitalIcon from "../images/klsHospitalIcon.webp";
import internationalAirportIcon from "../images/internationalAirportIcon.webp";
import domesticAirportIcon from "../images/domesticAirportIcon.webp";
import upcomingMetroIcon from "../images/upcomingMetroIcon.webp";
import cooperHospitalIcon from "../images/klsHospitalIcon.webp";

const items = [
  { icon: vileParleStationIcon, title: 'Vile Parle Station', distance: '1 km' },
  { icon: svRoadIcon, title: 'S V Road', distance: '400 mt' },
  { icon: captGoreFlyoverIcon, title: 'Capt. Gore East West Flyover', distance: '500 mt' },
  { icon: andheriStationIcon, title: 'Andheri Station', distance: '1.5 km' },
  { icon: petrolPumpIcon, title: 'Petrol Pump', distance: '400 mt' },
  { icon: banksIcon, title: 'Banks', distance: '200 mt' },
  { icon: irlaMarketIcon, title: 'Irla Market', distance: '500 mt' },
  { icon: parleMarketIcon, title: 'Parle Market', distance: '900 mt' },
  { icon: nmimsIcon, title: 'NMIMS Education Hub', distance: '1 km' },
  { icon: cnmSchoolIcon, title: 'CNM School', distance: '400 mt' },
  { icon: klsHospitalIcon, title: 'KLS Hospital', distance: '400 mt' },
  { icon: nanavatiHospitalIcon, title: 'Nanavati Hospital', distance: '1.8 km' },
  { icon: internationalAirportIcon, title: 'International Airport', distance: '3.3 km' },
  { icon: domesticAirportIcon, title: 'Domestic Airport', distance: '2.9 km' },
  { icon: upcomingMetroIcon, title: 'Upcoming Metro', distance: '1 km' },
  { icon: cooperHospitalIcon, title: 'Cooper Hospital', distance: '700 mt' },
];

const ProximityComponent = () => (
  <Box className="proximity-container">
    <h1 className="title">
      Close Proximity
    </h1>
    <Box className="icon-grid">
      {items.map((item, index) => (
        <Box className="icon-container" key={index}>
          <Box>
            <img loading="lazy" src={item.icon} alt={item.title} className="icon-image" />
          </Box>
          <p >{item.title}</p>
          <p className="distance">
            {item.distance}
          </p>
        </Box>
      ))}
    </Box>
  </Box>
);

export default ProximityComponent;
