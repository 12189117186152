import React from "react";
import Slider from "react-slick";
import Image1 from "../images/rooftop_image_1_optimized.webp";
import Image2 from "../images/rooftop_image_2_optimized.webp";
import "../style/BeforeFloorPlanning.css";

const projects = [{ imgSrc: Image1 }, { imgSrc: Image2 }];

const BeforeFloorPlanning = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="beforeFloorPlanning-container">
      <Slider {...settings} className="beforeFloorPlanning-slider">
        {projects.map((project, index) => (
          <div className="carousel-item" key={index}>
            <div className="beforeFloorPlanning-carousel-image-container">
              <img
              loading="lazy"
                src={project.imgSrc}
                // alt={`Image ${index + 1}`}
                alt="floorImage"
                className="beforeFloorPlanning-img"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const SampleNextArrow = ({ className, style, onClick, rightPosition }) => {
  return (
    <div
      className={`${className} custom-next-arrow1`}
      style={{ ...style, right: rightPosition }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = ({ className, style, onClick, leftPosition }) => {
  return (
    <div
      className={`${className} custom-prev-arrow1`}
      style={{ ...style, left: leftPosition }}
      onClick={onClick}
    />
  );
};

export default BeforeFloorPlanning;
