import React, { useState } from "react";
import { Button, Container, Box } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../style/FloorPlan.css";
import floorPlan1 from "../images/floorPlan1_3_optimized.webp";
import floorPlan2 from "../images/floorPlan2_optimized.webp";
import floorPlan3 from "../images/floorPlan3_optimized.webp";
import floorPlan4 from "../images/floorPlan4_optimized.webp";
import floorPlan5 from "../images/floorPlan5_optimized.webp";

const floorPlans = [
  {
    src: floorPlan1,
  },
  {
    src: floorPlan2,
  },
  {
    src: floorPlan3,
  },
  {
    src: floorPlan4,
  },
  {
    src: floorPlan5,
  },
];

const FloorPlanComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? floorPlans.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === floorPlans.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Container className="floor-plan-container" id="floor-plans">
      <h2 className="floor-plan-title">FLOOR PLANS</h2>
      <h4>Unleash your imagination with our super-sized layouts.</h4>
      <Box className="slider">
        <Button className="arrow left-arrow" onClick={handlePrevious}>
          <ArrowBackIosNewIcon className="custom-start-icon" />
        </Button>
        <Box className="image-container">
          <img
          loading="lazy"
            src={floorPlans[currentIndex].src}
            alt="Floor Plan"
          />
        </Box>
        <Button className="arrow right-arrow" onClick={handleNext}>
          <ArrowForwardIosIcon className="custom-end-icon" />
        </Button>
      </Box>
    </Container>
  );
};

export default FloorPlanComponent;
