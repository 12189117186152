import React from "react";
import { Container, Box } from "@mui/material";
import "../style/About.css";

const AboutComponent = () => {
  return (
    <Container>
      <Box className="Gp-family-section"  id="gp-advantage">
        <h1 className="gp-family-heading">Hear from our #GPFamily</h1>

        <p className="gp-family-text">
          “GP developed our property Tukaram Nivas, at M.B. Raut Road, Shivaji
          Park in 2008. Now called Saket CHSL. The quality of construction is
          excellent, till date no leakage, no structural problem, and the
          elevation is excellent. In fact it was the best elevated property at
          that time. All dealings and paper work was very transparent. The
          utilisation of space was excellent. All the office staff, site staff
          and the owner of GP, Mr. Rajendra Godshalwar Sir were very
          cooperative. Legal matters like society formation, conveyance deed
          were handled by professional staff from their office.
          <br />
          All the members and residents of our building are very happy and
          satisfied.”
        </p>
        <p className="gp-family-author"> - Ashish Palkhiwale</p>
      </Box>
      <Box className="advantage-section">
        <h1 className="advantage-heading">THE GP ADVANTAGE</h1>

        <p className="advantage-text">
          Founded in 2002 by real estate veteran Rajendra Godshalwar, Godshalwar
          Project Consultants & Developers (GP) stands as a beacon of excellence
          in Mumbai’s property development landscape. With over 30 years of
          expertise, Rajendra Godshalwar brings a wealth of experience to GP,
          ensuring each project is executed with precision and vision.
        </p>

        <p className="advantage-text">
          At GP, we specialize in re-development projects, breathing new life
          into Mumbai’s urban fabric. Our commitment to quality is unparalleled,
          evident in every detail of our work. We pride ourselves on assembling
          a distinguished professional team comprising eminent lawyers,
          architects, and RCC consultants, ensuring each project meets the
          highest standards of craftsmanship and design.
        </p>

        <p className="advantage-text">
          Over the years, GP has successfully completed numerous projects,
          leaving a lasting impact on Mumbai’s skyline. Our dedication to
          quality, integrity, and innovation sets us apart, making us the
          preferred choice for discerning clients seeking excellence in property
          development.
        </p>
      </Box>
    </Container>
  );
};

export default AboutComponent;
