import React, { useEffect, useRef } from "react";
import "../style/ProjectLocation.css";

const ProjectLocation = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: 0.3 // Adjust threshold as needed
    };

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Load iframe when it becomes visible
          iframeRef.current.src = iframeRef.current.dataset.src;
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(iframeRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className="project-location-main" id="project-location">
        <div className="project-location-title">
          <h1>PROJECT LOCATION</h1>
          <p>Location Map:</p>
        </div>
      </div>
      <div className="iframe-container">
          <iframe
            ref={iframeRef}
            data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.986100282485!2d72.8434013!3d19.1082657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9007266e34f%3A0x5af63f7e5c025d98!2sGP%20SKYLARK!5e0!3m2!1sen!2sin!4v1718890600870!5m2!1sen!2sin"
            width="100%"
            height="200"
            allowFullScreen=""
            loading="lazy"
            title="Location"
          ></iframe>
          </div>

    </>
  );
};

export default ProjectLocation;
