import React, { useState } from "react";
import "../style/Configurations.css";
import building_image from "../images/Elevation-01_7_optimized.webp";
// import building_image from "../images/Elevation-01.webp";
const PostConfigComponent = () => {
  const [postConfigState, setPostConfigState] = useState({
    read_more: false,
  });
  const handleReadMore = () => {
    setPostConfigState({ ...postConfigState, read_more: true });
  };
  const handleReadLess = () => {
    setPostConfigState({ ...postConfigState, read_more: false });
  };
  return (
    <>
      <div className="post-config" id="about-skylark">
        <img loading="lazy" src={building_image} alt="building" />
        <div className="post-config-bg"></div>
      </div>
      <div className="post-config-block-2">
        <div className="post-config-launching-text">
          <p className="launching-txt-p">Launching</p>
          <p className="launching-txt-p">the epitome of spacious</p>
          <p className="launching-txt-p">living in Vile Parle (W)</p>
        </div>
      </div>
      <div className="post-config-block-3">
        <div className="post-config-bigger-appartment">
          <p className="apt-txt-p">20% bigger apartments</p>
          <div className="custom-line"></div>

          <p className="apt-txt-p">Stunning Rooftop Garden</p>
        </div>
        <div className="post-config-bigger-appartment-desc">
          <p>
            Nestled in the heart of Parle, Skylark offers a lifestyle that
            blends convenience, tranquillity, and luxury. With premier
            international schools, the SVKM educational hub, S.V. Road,
            airports, and both Andheri and Vile Parle stations nearby, Skylark
            places you at the centre of everything.
          </p>
          <p>
            Despite its prime location, Skylark provides a peaceful retreat
            surrounded by lush greenery, a rare find in the city. This unique
            blend of city living with a serene neighbourhood ensures you enjoy
            the best of both worlds.
          </p>
          {!postConfigState.read_more && (<>
            <div className="read-more" onClick={() => handleReadMore()}>
            <p>Read More</p>
          </div>
          
          </>)}
          {postConfigState.read_more && (
            <>
              <p>
                Our apartments are approximately 20% larger than standard
                apartments, offering you the space to live, work, and entertain
                comfortably. Whether it’s a cozy evening or hosting friends,
                your home’s spacious layout is perfect for all life’s moments.
              </p>
              <p>
                At Skylark, you get unparalleled quality at a competitive price,
                making it the best value in Parle West for new constructions.
                From using the highest quality materials to providing top-notch
                fittings, we ensure every detail meets the highest standards.
              </p>
              <p>
                With the GP Developers advantage, you get to enjoy a seamless
                home-buying experience. We are renowned for timely delivery,
                full government approvals, and accelerated construction
                progress. Having secured full CC for this project, we are set to
                hand over possession well ahead of the RERA committed completion
                date. And this support and steadfastness extends beyond the
                sale, fostering lasting relationships and repeat business from
                satisfied customers.
              </p>
              <p>
                Join the Skylark community and find your dream home, crafted
                with care and surrounded by the best Vile Parle has to offer.{" "}
              </p>
              <div className="read-more" onClick={() => handleReadLess()}>
            <p>Read Less</p>
          </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PostConfigComponent;
