import React from "react";
import Slider from "react-slick";
import "../style/ProjectCarousel.css";
import ShaalinImg from "../images/Shaalin_optimized.webp";
import SharvariImg from "../images/sharvari_optimized.webp";
import SolitaireImg from "../images/Solitaire_optimized.webp";
import SaketImg from "../images/Saket_optimized.webp";

const projects = [
  { name: "SHAALIN", location: "Shivaji Park", imgSrc: ShaalinImg },
  { name: "SHARVARI", location: "Shivaji Park", imgSrc: SharvariImg },
  { name: "SOLITAIRE", location: "Santacruz East", imgSrc: SolitaireImg },
  { name: "SAKET", location: "Shivaji Park", imgSrc: SaketImg },
  // Repeat the projects to simulate an infinite loop
  { name: "SHAALIN", location: "Shivaji Park", imgSrc: ShaalinImg },
  { name: "SHARVARI", location: "Shivaji Park", imgSrc: SharvariImg },
  { name: "SOLITAIRE", location: "Santacruz East", imgSrc: SolitaireImg },
  { name: "SAKET", location: "Shivaji Park", imgSrc: SaketImg },
];

const ProjectCarouselComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container" id="gp-advantage">
      <h2>Some of Our Notable Completed Projects</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div className="carousel-item" key={index}>
            <div className="carousel-image-container">
              <img loading="lazy" src={project.imgSrc} alt={project.name} />
            </div>
            <div className="project-info">
              <h3>{project.name}</h3>
              <p>{project.location}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

export default ProjectCarouselComponent;
