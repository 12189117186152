import React from "react";
import EnquiryFormComponent from "./EnquiryFormComponent";
import '../style/FormContainer.css'
const FormContainer = () => {
  return (
    <div className="enquiry-form-container " id="enquiry-form">
        <h2>Enquiry Form</h2>
      <EnquiryFormComponent />
    </div>
  );
};

export default FormContainer;
